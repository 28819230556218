/* eslint-disable import/no-commonjs */
/* eslint-disable import/unambiguous */
// 上传到OSS文件路径
const OSS_URL = 'business/master/platform/projects/gaotusuyang';

const PATH_TYPE = process.env.PATH_TYPE || 'master';
const DOMAIN = 'naiyouxuexi.com';
const GTCNDOMAIN = 'naiyouxuexi.com';

/**
 * proxy:是否需要代理
 * proxyUrl: 代理地址
 * mUrl: 对应的M站地址
 */
// protocol
const default_config = {
    development: {
        env: 'dev',
        proxy: true,
        proxyUrl: `http://test-api.${DOMAIN}`,
        mUrl: `//test-m.${GTCNDOMAIN}`,
        pcUrl: `//test-www.${DOMAIN}`,
        bffProxyUrl: `//test-bapi.${DOMAIN}`,
        internalProxyUrl: `https://internal-test-api.${DOMAIN}/`,
        wenzUrl: 'https://test-room.wenzaizhibo.com',
        bigData: 'https://test-click.gaotu100.com/',
        cdnPath: '',
        partnerId: 37087513,
        bapiUrl: `//test-bapi.${DOMAIN}`,
        interactiveUrl: `https://test-interactive.${DOMAIN}`,
        logDir: './app/log',
        docUrl: `https://m.${DOMAIN}/gen-page/link/`
    },
    connectIP: {
        env: 'connectIP',
        proxy: true,
        proxyUrl: `//test-api.${DOMAIN}`,
        mUrl: `//test-m.${GTCNDOMAIN}`,
        pcUrl: `//test-www.${DOMAIN}`,
        bffProxyUrl: `//test-bapi.${DOMAIN}`,
        internalProxyUrl: `https://internal-test-api.${DOMAIN}/`,
        wenzUrl: 'https://test-room.wenzaizhibo.com',
        bigData: 'https://test-click.gaotu100.com/',
        cdnPath: '',
        partnerId: 37087513,
        bapiUrl: `//test-bapi.${DOMAIN}`,
        interactiveUrl: `https://test-interactive.${DOMAIN}`,
        logDir: '/apps/srv/instance/app/log',
        docUrl: `https://m.${DOMAIN}/gen-page/link/`
    },
    test: {
        env: 'test',
        proxy: false,
        proxyUrl: `//test-api.${DOMAIN}`,
        mUrl: `//test-m.${GTCNDOMAIN}`,
        pcUrl: `//test-www.${DOMAIN}`,
        bffProxyUrl: `//test-bapi.${DOMAIN}`,
        internalProxyUrl: `https://internal-test-api.${DOMAIN}/`,
        wenzUrl: 'https://test-room.wenzaizhibo.com',
        bigData: 'https://test-click.gaotu100.com/',
        cdnPath: '',
        partnerId: 37087513,
        bapiUrl: `//test-bapi.${DOMAIN}`,
        interactiveUrl: `https://test-interactive.${DOMAIN}`,
        logDir: '/apps/srv/instance/app/log',
        docUrl: `https://m.${DOMAIN}/gen-page/link/`
    },
    beta: {
        env: 'beta',
        proxy: false,
        proxyUrl: `//beta-api.${DOMAIN}`,
        mUrl: `//beta-m.${GTCNDOMAIN}`,
        pcUrl: `//beta-www.${DOMAIN}`,
        bffProxyUrl: `//test-bapi.${DOMAIN}`,
        internalProxyUrl: `https://internal-beta-api.${DOMAIN}/`,
        wenzUrl: 'https://beta2-room.wenzaizhibo.com',
        bigData: 'https://beta-click.gaotu100.com/',
        cdnPath: `https://gtoss.gsxcdn.com/${OSS_URL}/`,
        partnerId: 37087513,
        bapiUrl: `//test-bapi.${DOMAIN}`,
        interactiveUrl: `https://test-interactive.${DOMAIN}`,
        logDir: '/apps/srv/instance/app/log',
        docUrl: `https://m.${DOMAIN}/gen-page/link/`
    },
    master: {
        env: 'master',
        proxy: false,
        proxyUrl: `//api.${DOMAIN}`,
        mUrl: `//m.${GTCNDOMAIN}`,
        pcUrl: `//www.${DOMAIN}`,
        bffProxyUrl: `//bapi.${DOMAIN}`,
        internalProxyUrl: `https://internal-api.${DOMAIN}/`,
        wenzUrl: 'https://room.wenzaizhibo.com',
        bigData: 'https://click.gaotu100.com/',
        cdnPath: `https://gtoss.gsxcdn.com/${OSS_URL}/`,
        partnerId: 49752473,
        bapiUrl: `//bapi.${DOMAIN}`,
        interactiveUrl: `https://interactive.${DOMAIN}`,
        logDir: '/apps/srv/instance/app/log',
        docUrl: `https://m.${DOMAIN}/gen-page/link/`
    }
};
export default {
    env: default_config[PATH_TYPE]
};
