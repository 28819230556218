<template>
    <!-- <client-only> 修复 控制台警告，ssr渲染DOM结构与csrDOM结构不一致 -->
    <client-only>
        <div class="search" tabindex="0" @focus="getFocus">
            <div class="search-img"></div>
            <el-input
                ref="inputs"
                v-model="inputData"
                placeholder="搜索感兴趣的内容"
                class="search-content"
                debounce="200"
                @focus="isResultShow"
                @blur="isResultHidden"
                @input="getAssociateData"
                @keyup.enter.native="searchHander"
            />
            <div v-show="clearInputShow" class="delete-input" @click="clearInput">
            </div>
            <div
                class="search-btn"
                :style="{
                    opacity: resultShow ? 1 : 0
                }"
                @click="searchHander"
            >
                搜索
            </div>
            <div v-show="resultShow" class="search-result">
                <div v-if="histoyrShow" class="search-result-history">
                    <div class="search-result-history-title">
                        <span class="">搜索历史</span>
                        <span class="clear-all-list" @click="deleteHistory(-1)">清除记录</span>
                    </div>
                    <div class="search-result-history-content">
                        <div
                            v-for="(list, index) in history.slice(0, 8)"
                            :key="list"
                            class="search-result-history-content-list"
                        >
                            <div
                                class="search-result-history-content-detail"
                                @click="searchResultHandle(list)"
                            >
                                {{list}}
                            </div>
                            <div
                                class="search-result-history-content-delete"
                                @click="deleteHistory(index)"
                            ></div>
                        </div>
                    </div>
                </div>
                <template v-if="inputData !== ''">
                    <div
                        v-for="category in associateWord"
                        :key="category.searchWordType"
                        class="search-result-category"
                    >
                        <div class="search-result-category-title">
                            {{category.title}}
                        </div>
                        <div class="search-result-category-content">
                            <div
                                v-for="(item, index) in category.suggestionWords"
                                :key="item.suggestionWord"
                                class="search-result-category-content-list"
                                @click="searchResultHandle(item.suggestionWord, category.title, index)"
                            >
                                <div
                                    class="search-result-category-content-detail"
                                    v-html="item.htmlSuggestionWord || item.suggestionWord"
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </client-only>
</template>

<script>
import api from '~/api';
import {
    EVENT_ID_SEARCH_CLICK,
    EVENT_ID_JUMP_SEARCH_RESULT,
    EVENT_ID_RECOMMEND_WORD_CLICK,
    EVENT_ID_RECOMMEND_WORD_VIEW,
    EVENT_ID_DELETE_ALL_HISTORY,
    EVENT_ID_DELETE_HISTORY,
    EVENT_ID_CLEAR_INPUT_DATA
} from '@base/constants/searchResult';

export default {
    data() {
        return {
            inputData: '',
            // 历史记录
            history: [],
            resultShow: false,
            // 联想词
            associateWord: [],
            timer: null,
        };
    },
    computed: {
        histoyrShow() {
            return this.history.length > 0 && this.inputData === '';
        },
        clearInputShow() {
            // 聚焦+有内容
            return this.resultShow && this.inputData !== '';
        }
    },
    watch: {
        $route: {
            handler(val, oldVal) {
                if (val.name !== 'searchResult') {
                    this.inputData = '';
                }
                else {
                    const {searchWord = ''} = this.$route.query;
                    this.inputData = searchWord;
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.getHistoryData();
    },
    methods: {
        // 获取历史搜索记录
        getHistoryData() {
            try {
                const history = window.localStorage.getItem('searchHistory');
                this.history = JSON.parse(history) || [];
            }
            catch (error) {
                this.history = [];
            }
        },
        // 显示推荐搜索结果
        isResultShow() {
            this.resultShow = true;
        },
        // 隐藏推荐搜索结果
        isResultHidden() {
            this.resultShow = false;
        },
        // 删除搜索历史
        deleteHistory(index) {
            const {inputs} = this.$refs;
            inputs.focus();
            this.resultShow = true;
            const {localStorage} = window;
            if (index === -1) {
                this.history = [];
                localStorage.removeItem('searchHistory');
                this.$habo.haboClickReport(EVENT_ID_DELETE_ALL_HISTORY);
            }
            else {
                this.$habo.haboClickReport(EVENT_ID_DELETE_HISTORY, {
                    word: this.history[index]
                });
                this.history.splice(index, 1);
                this.addLocaltion();
            }
        },
        // 搜索事件触发
        searchHander() {
            this.$habo.haboClickReport(EVENT_ID_JUMP_SEARCH_RESULT, {
                search_words: this.inputData,
                search_method: '搜索按钮'
            });
            this.stickLocaltion();
        },
        // 重复内容置顶并跳转搜索结果页
        stickLocaltion(content = '') {
            const {inputData, history} = this;
            content = content || inputData;
            const searchWord = encodeURI(content);
            const index = history.findIndex(data => data === content);
            const {inputs} = this.$refs;
            if (content === '') {
                // 内容为空
                return;
            }
            if (index >= 0) {
                // 删除
                history.splice(index, 1);
            }
            history.unshift(content);
            this.addLocaltion();
            this.$router.push(`/searchResult?searchWord=${searchWord}`);
            // 跳转失焦
            inputs.blur();
            this.resultShow = false;
        },
        // 添加搜索历史
        addLocaltion() {
            const {localStorage} = window;
            let historyData = '';
            try {
                historyData = JSON.stringify(this.history);
            }
            catch (error) {
                historyData = '';
            }
            localStorage.setItem('searchHistory', historyData);
        },
        // 获取焦点
        getFocus() {
            const {inputs} = this.$refs;
            inputs.focus();
            this.$habo.haboClickReport(EVENT_ID_SEARCH_CLICK);
        },
        // 关键词联想
        getAssociateData() {
            if (this.inputData === '') {
                // 搜索栏内容清空，直接赋空值，避免页面抖动
                this.associateWord = [];
                return;
            }
            if (this.timer) {
                return;
            }
            this.timer = setTimeout(() => {
                const params = {
                    searchWord: this.inputData,
                    // 用户感兴趣品类，0代表所有品类
                    subjectIds: [0]
                };
                const options = {
                    params: {
                        os: 'h5-pc',
                        p_client: 18
                    }
                };
                this.$axios.$post(api.search.suggestionWords, params, options).then(res => {
                    if (res.code === 0) {
                        const {results = []} = res.data;
                        this.associateWord = results;
                        results.forEach(category => {
                            category.suggestionWords.forEach((item, index) => {
                                this.$habo.haboViewPage(EVENT_ID_RECOMMEND_WORD_VIEW, {
                                    search_type: category.title,
                                    search_words: this.inputData,
                                    word: item.suggestionWord,
                                    list_order: index + 1
                                });
                            });
                        });
                    }
                });
                this.timer = null;
            }, 200);
        },
        // 点击推荐搜索或历史记录触发事件
        searchResultHandle(content, category, index) {
            if (category) {
                this.$habo.haboClickReport(EVENT_ID_RECOMMEND_WORD_CLICK, {
                    search_type: category,
                    search_words: this.inputData,
                    word: content,
                    list_order: index + 1
                });
            }
            this.$habo.haboClickReport(EVENT_ID_JUMP_SEARCH_RESULT, {
                search_words: content,
                search_method: category ? '联想搜索' : '搜索历史'
            });
            this.inputData = content;
            this.getAssociateData();
            this.stickLocaltion(content);
        },
        // 清空输入
        clearInput() {
            this.$habo.haboClickReport(EVENT_ID_CLEAR_INPUT_DATA);
            this.inputData = '';
        }
    }
};
</script>

<style lang="less" scoped>
.search {
    float: left;
    top: 20px;
    display: flex;
    position: relative;

    &-content {
        width: 420px;
        height: 42px;
        position: relative;
    }

    &-img {
        background: url(https://i.gsxcdn.com/1883671559_vlkjptna.png);
        background-size: cover;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 11px;
        left: 16px;
        z-index: 1;
    }

    &-btn {
        width: 78px;
        height: 36px;
        position: absolute;
        right: 4px;
        top: 3px;
        background-color: #ff443d;
        border-radius: 28px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        opacity: 0;
        transition: opacity .2s;
    }

    &-result {
        width: 422px;
        position: absolute;
        top: 50px;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
        border-radius: 8px;

        &-history {
            margin: 16px 0;

            &-title {
                line-height: 38px;
                color: #14171c;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                padding: 0 16px 0 24px;
                justify-content: space-between;
            }

            .clear-all-list {
                font-size: 14px;
                color: #ff443d;
                font-weight: 400;
                cursor: pointer;
            }

            &-content {
                &-list {
                    padding: 0 16px 0 24px;
                    line-height: 36px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #717274;
                    display: flex;
                    justify-content: space-between;
                    position: relative;

                    &:hover {
                        background-color: #f3f3f5;
                    }
                }

                &-detail {
                    width: 342px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }

                &-delete {
                    width: 16px;
                    height: 16px;
                    background: url(https://i.gsxcdn.com/1871899177_kmb1wwo6.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 10px;
                    right: 16px;
                    cursor: pointer;
                }
            }
        }

        &-category {
            &-title {
                color: #b2b7c1;
                font-size: 14px;
                font-weight: 400;
                line-height: 38px;
                padding: 0 16px 0 24px;
            }

            &-content {
                &-list {
                    padding: 0 16px 0 24px;
                    line-height: 36px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #717274;
                    cursor: pointer;

                    &:hover {
                        background-color: #f3f3f5;
                    }
                }

                &-detail {
                    width: 342px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &:nth-last-child(1) {
                margin-bottom: 16px;
            }

            &:nth-child(1) {
                margin-top: 16px;
            }
        }
    }

    .delete-input {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 90px;
        top: 12px;
        background: url(https://i.gsxcdn.com/1871899177_kmb1wwo6.png);
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}
</style>

<style lang="less">
.search {
    .el-input__inner {
        width: 420px;
        height: 42px;
        border-radius: 28px;
        caret-color: #ff443d;
        border: 1px solid #eff2f8;
        background-color: #f6f8fc;
        padding: 0 82px 0 44px;
        font-size: 16px;
        color: #8b8fa1;
        transition: all .2s;

        &:focus {
            border: 1px solid #ff443d;
            background-color: #fff;

            &:hover {
                border: 1px solid #ff443d;
                background-color: #fff;
            }
        }

        &:hover {
            border: 1px solid #e8e8e8;
            background-color: #eeeeef;
        }
    }
}
</style>
